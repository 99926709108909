import {StyledPicker} from 'components/customer/Job/Job';
import React, {useCallback, useEffect, useMemo} from 'react';
import {Button} from 'react-bootstrap';
import {useConfirmationDialog} from 'shared';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ErrorMessage, Formik, FormikProps, Form} from 'formik';
import {useJobForm} from 'hooks';
import * as Yup from 'yup';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {useUpdateRequestedDeliveryDateMutation} from 'components/customer/Job/store/jobApi';
import {useJobContext} from 'contexts';
import {PartialJob} from 'shared/types/PartialJob';
import {DateTime, Format} from '@cabinetsbycomputer/datetime';

const validationSchema = Yup.object().shape({
    requestedDeliveryDate: Yup.string().required(
        'Please select a requested delivery date.'
    ),
});

const Error = styled.div`
    color: red;
    margin-top: -10px;
`;

const SubmitButton = styled(Button)`
    font-size: 0.85rem;
    width: 85px;
    padding: 6px 12px;
    justify-content: center;
`;

const useRequestedDeliveryPrompt = (
    requestedDeliveryDate: string,
    disablePrompt?: boolean,
    callback?: () => Promise<void>
) => {
    if (disablePrompt) {
        return {};
    }

    const {jobId} = useParams();
    const {minimumLeadTime = 0, allowDeliveryDateRequest} = useJobForm(!!jobId);
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const [updateJob, {isLoading}] = useUpdateRequestedDeliveryDateMutation();
    const {refresh} = useJobContext() as PartialJob;

    const initialValues = {
        requestedDeliveryDate: '',
    };

    const submitHandler = useCallback(
        ({requestedDeliveryDate}: typeof initialValues) => {
            void updateJob({
                jobId: Number(jobId) - 10000,
                requestedDeliveryDate,
            })
                .unwrap()
                .then(() => {
                    hideDialog();
                    refresh({});
                    if (!!callback) {
                        void callback();
                    }
                });
        },
        [callback]
    );

    const requestedDeliveryPromptConfig = {
        title: 'Select a new Requested Delivery Date',
        message: (
            <>
                <div>
                    The Requested Delivery Date for your job is no longer
                    available, please update to continue with your order.
                </div>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submitHandler}>
                    {({
                        values,
                        setFieldValue,
                        touched,
                        errors,
                    }: FormikProps<typeof initialValues>) => {
                        const handleDateChange = useCallback((date: Date) => {
                            void setFieldValue(
                                'requestedDeliveryDate',
                                DateTime.fromDate(date)
                                    .format(Format.Date)
                                    .toString()
                            );
                        }, []);
                        const preventDefault = useCallback(
                            (event: React.KeyboardEvent<HTMLDivElement>) =>
                                event.preventDefault(),
                            []
                        );
                        return (
                            <Form
                                id="cbc-job-form"
                                noValidate
                                style={{padding: '20px 0 5px'}}
                                className="cbc-form">
                                <div className="form-group">
                                    <label style={{fontSize: '0.85rem'}}>
                                        Requested Delivery Date:
                                        <span>*</span>
                                    </label>
                                    <StyledPicker>
                                        <DatePicker
                                            showIcon
                                            className={`form-control${
                                                touched.requestedDeliveryDate &&
                                                errors.requestedDeliveryDate
                                                    ? ' is-invalid'
                                                    : ''
                                            }`}
                                            wrapperClassName="full-width"
                                            dateFormat="dd MMM yyyy"
                                            selected={
                                                (values.requestedDeliveryDate &&
                                                    DateTime.parseCustom(
                                                        values.requestedDeliveryDate
                                                    ).get()) ||
                                                null
                                            }
                                            placeholderText="Select a requested delivery date"
                                            minDate={DateTime.now()
                                                .add({days: minimumLeadTime})
                                                .get()}
                                            onChange={handleDateChange}
                                            onKeyDown={preventDefault}
                                            toggleCalendarOnIconClick
                                        />
                                    </StyledPicker>
                                </div>
                                <ErrorMessage
                                    name="requestedDeliveryDate"
                                    component={Error}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                    }}>
                                    <SubmitButton
                                        disabled={isLoading}
                                        type="submit"
                                        className="button-blue btn-link">
                                        Submit
                                    </SubmitButton>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </>
        ),
        hideYesButton: true,
        hideNoButton: true,
        hideFooter: true,
    };

    const isDateExpired = useMemo(() => {
        if (
            requestedDeliveryDate === undefined ||
            requestedDeliveryDate === '' ||
            requestedDeliveryDate === null
        ) {
            return false;
        }

        const minLeadTime = DateTime.startOf('day').add({
            days: minimumLeadTime,
        });
        const deliveryDate = DateTime.parse(requestedDeliveryDate);

        const {days} = deliveryDate.diff(minLeadTime.get(), 'days');
        return allowDeliveryDateRequest && days < 0;
    }, [minimumLeadTime, allowDeliveryDateRequest, requestedDeliveryDate]);

    useEffect(() => {
        // this effect triggers onload when no callback function is supplied
        if (isDateExpired && !callback) {
            showDialog(requestedDeliveryPromptConfig);
        }
    }, [requestedDeliveryDate, isDateExpired]);

    return {
        requestedDeliveryPrompt: dialog,
        requestedDeliveryPromptConfig,
        isDateExpired,
    };
};

export default useRequestedDeliveryPrompt;
