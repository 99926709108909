import React, {useCallback, useEffect, useMemo} from 'react';
import {Icon, genericMessageHandler} from 'shared/helpers';
import {useJobContext, useNotificationContext} from 'contexts';
import {Button} from 'react-bootstrap';
import {PartialJob} from 'shared/types/PartialJob';
import {useFetchBenchesData} from 'components/customer/BTM/helper/useFetchBenchesData';
import {
    useJobResetMutation,
    useLazyGetExtraVariationsQuery,
} from 'components/customer/Job/store/jobApi';
import {JobStatusEnum} from 'shared/JobStatus';

interface VariationResetButtonProps {
    resetJob: () => void;
    noVariations: boolean;
    notRemovableExtraVariatons: boolean;
    extraVariation: boolean;
}

export const useVariationResetButton = (): VariationResetButtonProps => {
    const {job, refresh, room} = useJobContext() as PartialJob;
    const [jobReset] = useJobResetMutation();
    const {notify} = useNotificationContext();
    const benches = useFetchBenchesData();
    const [getExtraVariations, {data: extraVariationList}] =
        useLazyGetExtraVariationsQuery();

    useEffect(() => {
        if (job && job.id) {
            void getExtraVariations({jobId: job.id});
        }
    }, [job]);

    const onResetJob = () => {
        jobReset({jobId: job?.id})
            .unwrap()
            .then(() => {
                refresh({reloadFromServer: true});
                genericMessageHandler(
                    notify,
                    {
                        message: 'Job has been unsubmitted',
                    },
                    'success'
                );
            })
            .catch((error) => {
                throw error;
            });
    };

    const resetJob = useCallback(onResetJob, [job, benches]);

    const variations = useMemo(() => {
        const allCabinets = job?.rooms?.flatMap(
            (room) => room.jobCabinets || []
        );
        const allCabinetsCommentsEmpty =
            allCabinets?.every((item) => item.comment === '') ?? true;

        const allBenchtopsCommentsEmpty =
            benches?.every((bench) => !bench.isOld && bench.comment === '') ??
            true;

        const nonRemovableExtraVariation =
            extraVariationList?.every((item) => !item.removable) ?? true;

        return {
            notRemovable: nonRemovableExtraVariation,
            noVariations: allCabinetsCommentsEmpty && allBenchtopsCommentsEmpty,
            extraVariation: extraVariationList?.length > 0,
        };
    }, [job, benches, extraVariationList, room]);

    return {
        resetJob,
        noVariations: variations.noVariations,
        notRemovableExtraVariatons: variations.notRemovable,
        extraVariation: variations.extraVariation,
    };
};

export const VariationResetButton = () => {
    const {job} = useJobContext() as PartialJob;
    const {resetJob} = useVariationResetButton();

    return job?.accepted && job.status < JobStatusEnum.PENDING_PAYMENT ? (
        <Button
            variant="link"
            onClick={resetJob}
            className="button-blue btn-sm">
            <Icon iconName="Button-Edit.svg" />
            <span>Edit Job</span>
        </Button>
    ) : null;
};
